<template>
  <div class="page">
    <img src="./header.jpg" class="img" />
    <div class="list" >
      <div class="item" v-for="(item, index) in $store.state.teacherList" :key="index">
        <div class="title">{{item.title}}</div>
        <div class="attr">
          <div class="tag" v-for="tag in item.tag" :key="tag">{{ tag }}</div>
        </div>
        <div class="footer">
          <div> <span class="unit">已有 </span><span class="val">{{ item.student }}</span><span class="txt"> 报名</span> </div>
          <div class="btn" @click="toDetail(index)">查看详情</div>
        </div>
      </div>
      <div style="height: 200px;"></div>
      <div class="footer-link" @click="show = true">我想学习</div>
    </div>
    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>扫描下方二维码联系客服</template>
    </van-image-preview>
  </div>
</template>
<script>
export default {
  name: 'TeacherList',
  data() { 
    return {
      show: false,
      images: [require('@/assets/1702208809889.png')]
    }
  },
  methods: {
    toDetail(index) {
      this.$router.push(`/teacher/detail?index=` + index)
    }
  }
}


</script>
<style lang="less" scoped>
    *{
      margin: 0;
      padding: 0;
    }
    .page{
      width: 100vw;
      height: auto;
    }
    .img{
      width: 100vw;
      height: 200px;
    }
    .list{
      width: 100vw;
      box-sizing: border-box;
      padding: 0 15px;
    }
    .item{
      width: 100%;
      height: auto;
      padding: 10px 0;
      border-bottom: 1px solid #F7F7F7;
    }
    .title{
      width: 100%;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .attr{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .tag{
      padding: 2px 10px;
      background: rgba(255,102,13, .5);
      color: #FFF;
      font-size: 14px;
      margin-right: 10px;
      border-radius: 8px;
      margin-top: 5px;
    }
    .footer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;
    }
    .unit{
      font-size: 12px;
      color: rgb(255,102,13);
    }
    .val{
      font-size: 20px;
      font-weight: 600;
      color: rgb(255,102,13);
    }
    .txt{
      font-size: 12px;
      color: rgb(255,102,13);
    }
    .btn{
      background-color: rgb(255,102,13);
      color: #FFF;
      text-align: center;
      width: 80px;
      border-radius: 8px;
      height: 30px;
      font-size: 14px;
      line-height: 30px;
    }
    .footer-link{
      position: fixed;
      background: rgb(255,102,13);
      font-size: 16px;
      bottom: 50px;
      color: #FFF;
      height: 40px;
      line-height: 40px;
      width: 80vw;
      left: 10vw;
      text-align: center;
      border-radius: 40px;
    }
</style>