import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        teacherList: [{
                title: '10年后端程序收徒带新，学习指导，疑难解答',
                price: '100',
                student: '78',
                name: '张先生',
                message: '人生就像一场旅行，有风景也有风雨。但只要心中有梦想，有信念，就能够勇敢地走下去。不要害怕失败，因为失败是成功的垫脚石。只有经历过挫折和磨难，才能更好地理解人生的意义和价值。',
                info: '5年研发经验、3年产品设计及项目管理经验，历任产品经理、企业解决方案咨询顾问、项目经理、Java大数据工程师。参与设计并研发海关缉私大数据平台、国家生态环境数据中台、国家舆情大数据平台等多个国家重点信息化项目。',
                tag: [
                    'Java',
                    'PHP',
                    'Python',
                    '爬虫',
                    '区块链',
                    '高并发',
                    '分布式',
                    '数据库优化',
                ]
            },
            {
                title: '高级前端工程师在线指导，在线答疑，手把手教学',
                price: '80',
                student: '56',
                name: '李先生',
                message: '学习是一种态度，一种对生活的热爱。无论你面临什么挑战，都要保持好奇心和决心。每一个困难都是一个机会，每一次失败都是一次成长的起点。我期待看到你在每一次挑战中都能有所收获，不断进步。',
                info: '具备多年电商&互联网移动APP市场&物联网行业的软件开发经验，对主流MVVM技术框架(React、微信小程序、Vue)，UI框架，模块化，原生JavaScript，Node.js，数据库，代码管理工具等有着丰富的实战运用经验和团队协作经验。曾带领团队完成企业级系统应用设计、搭建、开发、上线等一整套的流程。帮助自学的小白指明学习方向，节省时间提高效率；',
                tag: [
                    'Vue',
                    'React',
                    '小程序',
                    'uniapp',
                    'websocket',
                    'WebGL',
                    'Typescript',
                    '单元测试'
                ]
            },
            {
                title: '前端架构师带领小白入前端',
                price: '70',
                student: '47',
                name: '刘先生',
                message: '要记住学习是一种持久的追求，而成功的关键在于坚持不懈。正如古人所云：“千里之行，始于足下。”每一次的努力都是通往成功的阶梯。',
                info: '拥有多年电商&互联网金融行业的软件开发经验，曾在招商基金、eims、TCL集团担任项目经理与技术负责人职位，对主流的MVVM框架、移动端开发框架、UI框架、SPA、构建工具、Serverless、代码版本管理软件、模块化、RxJS、Node.js、数据库等技术有丰富的开发和团队协同经验。帮助自学的小白指明学习方向，节省时间提高效率；',
                tag: [
                    'Vue',
                    'React',
                    '函数式编程',
                    'JavaScript',
                    'NodeJs',
                    'sass',
                    'mysql'
                ]
            },
            {
                title: '高级前端工程师兼职指导学习',
                price: '60',
                student: '35',
                name: '刘先生',
                message: '以勤为径，以恒为座，以变为锋，以谦为身，以慎为履。不畏艰险，不惧困难，勇攀高峰，不断超越。',
                info: '10年IT行业开发经验，曾在某国内一线互联网公司任职担任研发总监，主导电商、人工智能、大数据等多种项目的研发；帮助自学的小白指明学习方向，节省时间提高效率；',
                tag: [
                    'javascript',
                    'jquery',
                    '小程序',
                    'uniapp',
                    'react-native',
                    'vue3',
                    '性能调优',
                    '代码优化'
                ]
            },
            {
                title: '高级安卓开发工程师兼职带新手',
                price: '70',
                student: '20',
                name: '赵先生',
                message: '人生就像一条河流，有时平静如镜，有时波涛汹涌。无论你遇到什么困难和挑战，都要保持信心和勇气。不要害怕失败，因为失败是成功的基石。每一次的失败都会让你更加坚强，更加成熟。',
                info: '10年IT行业开发经验，担任公司TeamLeader，负责移动开发架构，封装公共组件。主导过电商，即时通讯，车载设备，保险系统，短信网关，wap平台，手机游戏，手机看漫画，手机学英语等软件。现利于业余时间帮助新人。',
                tag: [
                    '数据结构',
                    '网络架构',
                    '六大设计原则',
                    'Android安全原理',
                    '深入理解端上设计范式',
                    '掌握模块化',
                    '动态部署'
                ]
            }
        ]
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})