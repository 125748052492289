import Vue from 'vue'
import VueRouter from 'vue-router'
import TeacherList from '../views/teacher/list/TeacherList.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'TeacherList',
        component: TeacherList
    }, {
        path: '/teacher/list',
        name: 'TeacherList',
        component: TeacherList
    },
    {
        path: '/teacher/detail',
        name: 'Detail',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/teacher/detail/TeacherDetail.vue')
    },
    {
        path: '/law/index',
        name: 'Law',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/law/LawIndex.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router